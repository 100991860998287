import React from "react";
import Logo from "../../assets/svg/Europos-egzaminas-logo.svg";
import LRT from "../../assets/svg/logo-lrt.svg";
import EU from "../../assets/svg/logo-europos-komisija.svg";
import { Link, navigate } from "gatsby";
import Burger from "../../assets/svg/menuIcon.svg";
import { GhostButton } from "../Buttons";
import "./Header.scss";

const Navbar = () => {
    function closeNav() {
      document.getElementById("menu").style.width = "0";
    }
    function openNav() {
      document.getElementById("menu").style.width = "100vw";
    }

    function scrollToApie() {
      if (typeof window !== 'undefined') {
        if (window.location.pathname === '/') {
          const element = document.getElementById("target1");
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        else{
          navigate('/');
          setTimeout(() => {
            const element = document.getElementById("target1");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 200);
        }
      }
    }
    
    function scrollToTrain() {
      if (typeof window !== 'undefined') {
        if (window.location.pathname === '/') {
          const element = document.getElementById("target2");
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        else{
          navigate('/');
          setTimeout(() => {
            const element = document.getElementById("target2");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 200);
        }
      }
    }
    
    function scrollToAmbasada() {
      if (typeof window !== 'undefined') {
        if (window.location.pathname === '/') {
          const element = document.getElementById("target3");
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        else{
          navigate('/');
          setTimeout(() => {
            const element = document.getElementById("target3");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 200);
        }
      }
    }

  function scrollToOrganizacijoms() {
    if (typeof window !== 'undefined') {
      if (window.location.pathname === '/') {
        const element = document.getElementById("target4");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      else{
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById("target4");
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 200);
      }
    }
  }
    
    return (
      <div className="navbar">
        <div className="leftSide">
          <Link to="/">
            <img src={Logo} alt="Europos egzamino logotipas"/>
          </Link>
        </div>
        <div className="newRight">
          <div className="midSide">
            <GhostButton variant="meniu" onClick={scrollToApie} aria-label="Skaityti taisykles">APIE EGZAMINĄ</GhostButton>
            <GhostButton variant="meniu" onClick={scrollToTrain} aria-label="Skaityti taisykles">EGZAMINO TRENIRUOTĖ</GhostButton>
            <GhostButton variant="meniu" onClick={scrollToAmbasada} aria-label="Skaityti taisykles">TAPK EGZAMINO AMBASADORIUMI</GhostButton>
            <GhostButton variant="meniu" onClick={scrollToOrganizacijoms} aria-label="Skaityti taisykles">ORGANIZACIJŲ IŠŠŪKIS</GhostButton>
          </div>
          <div className="hiddenMenu" id="menu">
            <button className="closebtn" onClick={closeNav} aria-label="Uzdaryti meniu">&times;</button>
            <GhostButton variant="white" onClick={() => { closeNav(); scrollToApie(); }}>APIE EGZAMINĄ</GhostButton>
            <GhostButton variant="white" onClick={() => { closeNav(); scrollToTrain(); }} >EGZAMINO TRENIRUOTĖ</GhostButton>
            <GhostButton variant="white" onClick={() => { closeNav(); scrollToAmbasada(); }}>TAPK EGZAMINO AMBASADORIUMI</GhostButton>
            <GhostButton variant="white" onClick={() => { closeNav(); scrollToOrganizacijoms(); }}>ORGANIZACIJŲ IŠŠŪKIS</GhostButton>
          </div>
          <div className="rightSide" id="CTA-Meniu">
            <div id="sponTitle">
              Kviečia laikyti:
            </div>
            <div className="sponDiv">
              <div id="euLogo">
                <a href = "https://lithuania.representation.ec.europa.eu/index_lt"target="_blank" rel="noreferrer">
                  <img src={EU} alt="Europos komisijos logotipas" title="Europos komisija"/>
                </a>
              </div>
              <div id="lrtLogo">
                <a href = "https://www.lrt.lt/" target="_blank" rel="noreferrer">
                  <img src={LRT} alt="LRT logotipas" title="LRT"/>
                </a>
              </div>
            </div>
          </div>
            <button onClick={openNav} id="menuBut" aria-label="Atidaryti meniu">
              <img src={Burger} alt="Meniu ikonėlė" id="menuIcon"/>
            </button>
        </div>
      </div>
    );
  }
  
  export default Navbar;